var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.disabled
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success px-5",
                      attrs: { type: "button" },
                      on: { click: _vm.submeter }
                    },
                    [_vm._v(_vm._s(_vm.model.id == 0 ? "Criar" : "Alterar"))]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      _vm.$router.push({ name: "sistema.hospital.lista" })
                    }
                  }
                },
                [_vm._v("Voltar")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row mt-4" },
        [
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            attrs: {
              name: "nome",
              disabled: _vm.disabled,
              "data-vv-as": "Nome",
              label: "Nome",
              modelErrors: _vm.errors,
              cols: 7
            },
            model: {
              value: _vm.model.nome,
              callback: function($$v) {
                _vm.$set(_vm.model, "nome", $$v)
              },
              expression: "model.nome"
            }
          }),
          _c("f-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "##.###.###/####-##",
                expression: "'##.###.###/####-##'"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, cnpj: true },
                expression: "{ required: true, cnpj: true }"
              }
            ],
            attrs: {
              name: "cnpj",
              disabled: _vm.disabled,
              "data-vv-as": "CNPJ",
              label: "CNPJ",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.cnpj,
              callback: function($$v) {
                _vm.$set(_vm.model, "cnpj", $$v)
              },
              expression: "model.cnpj"
            }
          }),
          _c("f-selectsearch", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            ref: "estadoId",
            attrs: {
              label: "UF do Hospital",
              name: "estadoId",
              disabled: _vm.disabled,
              "data-vv-as": "UF",
              modelErrors: _vm.errors,
              cols: 2,
              searchFunction: _vm.getAllEstado,
              searchAllOptions: true,
              removeOptionsOnSelect: false
            },
            on: { select: _vm.selecionarEstado },
            model: {
              value: _vm.model.estadoId,
              callback: function($$v) {
                _vm.$set(_vm.model, "estadoId", $$v)
              },
              expression: "model.estadoId"
            }
          }),
          _c("f-switch", {
            attrs: {
              name: "ativo",
              disabled: _vm.disabled,
              cols: 12,
              label: _vm.model.ativo ? "Ativo" : "Inativo"
            },
            model: {
              value: _vm.model.ativo,
              callback: function($$v) {
                _vm.$set(_vm.model, "ativo", $$v)
              },
              expression: "model.ativo"
            }
          }),
          !_vm.disabled
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-6 d-flex justify-content-start" },
                  [_c("h4", { staticClass: "mt-3" }, [_vm._v("Convênios")])]
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-6 d-flex justify-content-end" },
                  [
                    _c(
                      "label",
                      { staticClass: "d-inline-flex align-items-center" },
                      [
                        _vm._v(" Filtrar: "),
                        _c("b-form-input", {
                          staticClass: "form-control form-control-sm ms-2",
                          attrs: { type: "search" },
                          on: { input: _vm.filtrarConvenios },
                          model: {
                            value: _vm.convenios.filter.filtrar,
                            callback: function($$v) {
                              _vm.$set(_vm.convenios.filter, "filtrar", $$v)
                            },
                            expression: "convenios.filter.filtrar"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("ui-table", {
                  attrs: {
                    id: "convenio-convenio",
                    colunas: _vm.convenios.colunas,
                    items: _vm.conveniosFiltrados,
                    loading: _vm.convenios.loading,
                    "force-script-ordenacao": true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function(row) {
                          return [
                            !_vm.disabled
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "outline-danger",
                                      small: "",
                                      title: "remover"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.removerConvenio(row.data)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "uil uil-multiply icon-size"
                                    })
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      !_vm.disabled
                        ? {
                            key: "hactions",
                            fn: function() {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "outline-success",
                                      small: "",
                                      title: "adicionar"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.adicionarConvenio()
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "uil uil-plus" })]
                                )
                              ]
                            },
                            proxy: true
                          }
                        : null,
                      {
                        key: "renderCell(nome)",
                        fn: function(data) {
                          return [
                            data.item.id > 0
                              ? _c("div", [_vm._v(_vm._s(data.value))])
                              : _c("f-selectsearch", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  key: "convenio" + data.item.id,
                                  attrs: {
                                    name: "selconv",
                                    placeholder:
                                      "Digite para pesquisar o convênio",
                                    labelField: "nome",
                                    searchFunction: _vm.pesquisarConvenio,
                                    modelErrors: _vm.errors,
                                    "data-vv-as": "Convênio"
                                  },
                                  on: { select: _vm.selecionarConvenio }
                                })
                          ]
                        }
                      },
                      {
                        key: "renderCell(codigo)",
                        fn: function(data) {
                          return [
                            _vm.disabled
                              ? _c("div", [_vm._v(_vm._s(data.value))])
                              : _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: true },
                                      expression: "{required: true}"
                                    }
                                  ],
                                  key: "codigo" + data.item.id,
                                  class:
                                    "form-control" +
                                    (_vm.errors &&
                                    _vm.errors.has("codigo" + data.item.id)
                                      ? " is-invalid"
                                      : ""),
                                  attrs: {
                                    name: "codigo" + data.item.id,
                                    placeholder:
                                      "Código do Hospital no Convênio",
                                    "data-vv-as": "Código"
                                  },
                                  model: {
                                    value: data.item.codigo,
                                    callback: function($$v) {
                                      _vm.$set(data.item, "codigo", $$v)
                                    },
                                    expression: "data.item.codigo"
                                  }
                                }),
                            _vm.errors &&
                            _vm.errors.has("codigo" + data.item.id)
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("codigo" + data.item.id)
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "renderCell(valoracaoServico)",
                        fn: function(data) {
                          return [
                            _vm.disabled
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(_vm._f("toCurrency")(data.value))
                                  )
                                ])
                              : _c("money", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "min_value:0.01",
                                      expression: "'min_value:0.01'"
                                    }
                                  ],
                                  key: "valoracao" + data.item.id,
                                  class:
                                    "text-end form-control" +
                                    (_vm.errors &&
                                    _vm.errors.has("valoracao" + data.item.id)
                                      ? " is-invalid"
                                      : ""),
                                  attrs: {
                                    name: "valoracao" + data.item.id,
                                    "data-vv-as": "Valoração",
                                    "data-vv-name": "valoracao" + data.item.id
                                  },
                                  model: {
                                    value: data.item.valoracaoServico,
                                    callback: function($$v) {
                                      _vm.$set(
                                        data.item,
                                        "valoracaoServico",
                                        $$v
                                      )
                                    },
                                    expression: "data.item.valoracaoServico"
                                  }
                                }),
                            _vm.errors &&
                            _vm.errors.has("valoracao" + data.item.id)
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "valoracao" + data.item.id
                                      )
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }